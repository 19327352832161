import * as React from 'react';
import { useEffect, useState } from 'react';
import { styled } from '@stitches/react';
import { atomWithStorage } from 'jotai/utils';
import { useAtom } from 'jotai';
import { fillInGrid } from './shift-util';

const Box = styled('div', {});
const NumberContainer = styled('div', {
  display: 'grid',
});
const SizeInput = styled('input', {});
const NumberBox = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'gainsboro',
  border: '1px solid black',
  aspectRatio: '1 / 1',
  cursor: 'pointer',
  transition: 'background-color .3s ease-in-out',
  '&:hover': {
    transitionDuration: '0s',
    backgroundColor: 'lightgray',
  },
});

const ColorButton = styled('button', {
  backgroundColor: 'gainsboro',
  border: '1px solid black',
  fontSize: '13px',
  padding: '10px 15px',
  cursor: 'pointer',
  borderRadius: 999,
  '&:hover': {
    backgroundColor: 'lightgray',
  },
});

const COLORS = {
  YELLOW: '#ffd700',
  RED: '#ff0000',
  GREEN: '#00ff00',
  BLUE: '#0000ff',
  BROWN: '#a52a2a',
  BLACK: '#000000',
  ORANGE: '#ffa500',
  VIOLET: '#ee82ee',
  WHITE: '#ffffff',
};

const WHITE = 'WHITE';

const DEFAULT_SIZE = 10;

// should be row then column
const createValuesChart = (row: number, columns: number) =>
  Array.from({ length: row }, () =>
    Array.from({ length: columns }, () => WHITE)
  );

const chartValuesAtom = atomWithStorage<string[][]>(
  'chartValuesAtom',
  createValuesChart(DEFAULT_SIZE, DEFAULT_SIZE)
);

const rowCountAtom = atomWithStorage<number>('rowCount', 10);

const columnCountAtom = atomWithStorage<number>('columnCount', 10);

function Home() {
  const [lastClick, setLastClick] = useState<number[]>([0, 0]);

  const [currentColor, setCurrentColor] = useState<string>(
    Object.keys(COLORS).at(0) as string
  );
  const [chartValues, setChartValues] = useAtom(chartValuesAtom);
  const [columnCount, setColumnCount] = useAtom(columnCountAtom);
  const [rowCount, setRowCount] = useAtom(rowCountAtom);
  const [colorMap, setColorMap] = useState<Record<string, number[]>>({});

  const updateColorMap = (chartValuesParam: string[][]) => {
    const newColorMap: Record<string, number[]> = {};
    Object.entries(chartValuesParam.flat()).map(([index, value]) => {
      if (value === WHITE) {
        return;
      }

      if (!newColorMap[value]) {
        newColorMap[value] = [];
      }
      newColorMap[value].push(parseInt(index, 10) + 1);
    });
    setColorMap(newColorMap);
  };

  const handleGridClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const row = parseInt(
      (event as unknown as React.ChangeEvent<HTMLDivElement>).target.dataset
        .row ?? '-1',
      10
    );
    const column = parseInt(
      (event as unknown as React.ChangeEvent<HTMLDivElement>).target?.dataset
        .column ?? '-1',
      10
    );
    const newChartValues = [...chartValues];
    newChartValues[row][column] = currentColor;
    if (event.shiftKey) {
      const [lastRow, lastColumn] = lastClick;
      fillInGrid(
        newChartValues,
        lastRow,
        lastColumn,
        row,
        column,
        currentColor
      );

      //   console.log('currentRow', row, 'currentColumn', column);
      //   console.log('lastRow', lastRow, 'lastColumn', lastColumn);
      //   const rowDirection = row > lastRow ? -1 : 1;
      //   const columnDirection = column > lastColumn ? -1 : 1;
      //   console.log(
      //     'rowDirection',
      //     rowDirection,
      //     'columnDirection',
      //     columnDirection
      //   );
      //   let i = 0;
      //   for (
      //     let rowLocal = row;
      //     rowDirection === -1 ? rowLocal <= rowCount : rowLocal >= rowCount;
      //     rowLocal += rowDirection
      //   ) {
      //     console.log('rowLocal', rowLocal);
      //     i++;
      //     if (i > 10) {
      //       break;
      //     }
      //     console.log('colLocal', column);
      //     for (
      //       let colLocal = column;
      //       console.log('colLocal', { colLocal }) || columnDirection === 1
      //         ? colLocal <= columnCount
      //         : colLocal >= columnCount;
      //       colLocal += columnDirection
      //     ) {
      //       console.log('col', colLocal);
      //       i++;
      //       if (i > 10) {
      //         break;
      //       }
      //       console.log('colLocal', colLocal);
      //       if (newChartValues[colLocal]?.[rowLocal]) {
      //         newChartValues[colLocal][rowLocal] = currentColor;
      //       }
      //     }
      //   }
    }
    updateColorMap(newChartValues);
    setChartValues(newChartValues);
    setLastClick([row, column]);
  };

  const updateSize = (rows: number, columns: number) => {
    setRowCount(rows);
    setColumnCount(columns);
    const newChartValues = createValuesChart(rows, columns);
    setChartValues(newChartValues);
    updateColorMap(newChartValues);
  };

  // useEffect(() => {
  //   let dragged;
  //   const start = 0;
  //   const end = 0;
  //   /* events fired on the draggable target */
  //   document.addEventListener(
  //     'drag',
  //     function (event) {
  //       console.log('drag', event?.target?.dataset?.value);
  //     },
  //     false
  //   );
  //
  //   document.addEventListener(
  //     'dragstart',
  //     function (event) {
  //       // store a ref. on the dragged elem
  //       dragged = event.target;
  //       // make it half transparent
  //       console.log('start', event?.target?.dataset?.value);
  //     },
  //     false
  //   );
  //
  //   document.addEventListener(
  //     'dragend',
  //     function (event) {
  //       // reset the transparency
  //       console.log('dragend', event?.target?.dataset?.value);
  //       handleGridClick(event);
  //     },
  //     false
  //   );
  //
  //   /* events fired on the drop targets */
  //   document.addEventListener(
  //     'dragover',
  //     function (event) {
  //       // prevent default to allow drop
  //       event.preventDefault();
  //       console.log('dragover', event?.target?.dataset?.value);
  //     },
  //     false
  //   );
  //
  //   document.addEventListener(
  //     'dragenter',
  //     function (event) {
  //       // highlight potential drop target when the draggable element enters it
  //       if (event.target.className == 'dropzone') {
  //         event.target.style.background = 'purple';
  //       }
  //       console.log('dragenter', event?.target?.dataset?.value);
  //     },
  //     false
  //   );
  //
  //   document.addEventListener(
  //     'dragleave',
  //     function (event) {
  //       // reset background of potential drop target when the draggable element leaves it
  //       if (event.target.className == 'dropzone') {
  //         event.target.style.background = '';
  //       }
  //       console.log('dragleave', event?.target?.dataset?.value);
  //     },
  //     false
  //   );
  //
  //   document.addEventListener(
  //     'drop',
  //     function (event) {
  //       // prevent default action (open as link for some elements)
  //       event.preventDefault();
  //       // move dragged elem to the selected drop target
  //       // if (event.target.className == 'dropzone') {
  //       //   event.target.style.background = '';
  //       //   dragged.parentNode.removeChild(dragged);
  //       //   event.target.appendChild(dragged);
  //       // }
  //       console.log('drop', event?.target?.dataset?.value);
  //     },
  //     false
  //   );
  // }, []);

  return (
    <Box
      css={{ width: '80vw', display: 'flex', flexDirection: 'column', gap: 15 }}
    >
      <Box as={'h2'} css={{ margin: 0 }}>
        Home
      </Box>
      <Box>
        <SizeInput
          type="number"
          value={columnCount}
          onChange={(e) => updateSize(rowCount, parseInt(e.target.value, 10))}
        />
        <SizeInput
          type="number"
          value={rowCount}
          onChange={(e) =>
            updateSize(parseInt(e.target.value, 10), columnCount)
          }
        />
      </Box>
      <Box css={{ display: 'flex', justifyContent: 'space-around' }}>
        {Object.keys(COLORS).map((color) => (
          <ColorButton
            key={color}
            style={{
              backgroundColor: COLORS[color],
              fontWeight: currentColor === color ? 'bold' : 'normal',
            }}
            onClick={() => setCurrentColor(color)}
          >
            {color}
          </ColorButton>
        ))}
      </Box>
      <NumberContainer
        css={{
          gridTemplateColumns: `repeat(${columnCount}, 1fr)`,
          fontSize: 9,
        }}
        onClick={handleGridClick}
      >
        {chartValues.map((row, rowIndex) =>
          row.map((color, columnIndex) => (
            <NumberBox
              draggable={true}
              role={'button'}
              key={`${color}-${rowIndex}-${columnIndex}`}
              data-row={rowIndex}
              data-column={columnIndex}
              css={{ backgroundColor: chartValues[rowIndex][columnIndex] }}
            >
              {rowIndex * columnCount + columnIndex + 1}
            </NumberBox>
          ))
        )}
      </NumberContainer>
      <h1>Number List</h1>
      <Box css={{ maxWidth: 600, textAlign: 'left', mb: 16, minHeight: 40 }}>
        {Object.keys(COLORS).map(
          (color) =>
            colorMap[color] && (
              <div key={color}>
                <strong>{color}:</strong>
                {colorMap[color].join(', ')}
              </div>
            )
        )}
      </Box>
    </Box>
  );
}

export default Home;
