export const fillInGrid = (
  grid: string[][],
  lastRow: number,
  lastCol: number,
  newRow: number,
  newCol: number,
  newValue: string
) => {
  const rowDirection = newRow > lastRow ? 1 : -1;
  const colDirection = newCol > lastCol ? 1 : -1;

  //    fill in the items between the last and new row/col
  for (let i = lastRow; i !== newRow + rowDirection; i += rowDirection) {
    for (let j = lastCol; j !== newCol + colDirection; j += colDirection) {
      grid[i][j] = newValue;
    }
  }

  return grid;
};
